<template>
    <v-main>
        <v-container v-if="loadingEmployee">
            <v-skeleton-loader class="mt-3 mb-5" type="heading" />
            <v-skeleton-loader type="paragraph" />
        </v-container>
        <v-container v-else>
            <v-card
                v-if="employee.id"
                rounded
                outlined
            >
                <v-card-title class="text-h4">{{ employeeName }}</v-card-title>
                <v-card-subtitle>{{ employee.title }}</v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col v-if="employee.division" cols="12" sm="4">
                            <div class="overline">Division</div>
                            {{ employee.division.long_name }}
                        </v-col>
                        <v-col v-if="employee.email" cols="12" sm="4">
                            <div class="overline">Email</div>
                            <a :href="`mailto:${employee.email}`" v-text="employee.email" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </v-main>
</template>
<script>
//import axios from 'axios';
import { onMounted, computed, watch } from '@vue/composition-api';

import useEmployees from '@/composables/population/useEmployees';

export default {
    components: {},
    setup(_, { root }) {
        const employeeId = computed(() => root.$route.params.uni);

        const {
            employee,
            employeeName,
            loadEmployee,
            loadingEmployee
        } = useEmployees({id: employeeId.value});

        onMounted(() => {
            loadEmployee();
        });

        watch(employeeId, (val) => loadEmployee(val));

        return {
            employee,
            employeeName,
            loadingEmployee
        };
    }
};
</script>