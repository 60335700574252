import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[(_vm.loadingEmployee)?_c(VContainer,[_c(VSkeletonLoader,{staticClass:"mt-3 mb-5",attrs:{"type":"heading"}}),_c(VSkeletonLoader,{attrs:{"type":"paragraph"}})],1):_c(VContainer,[(_vm.employee.id)?_c(VCard,{attrs:{"rounded":"","outlined":""}},[_c(VCardTitle,{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.employeeName))]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.employee.title))]),_c(VCardText,[_c(VRow,[(_vm.employee.division)?_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"overline"},[_vm._v("Division")]),_vm._v(" "+_vm._s(_vm.employee.division.long_name)+" ")]):_vm._e(),(_vm.employee.email)?_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"overline"},[_vm._v("Email")]),_c('a',{attrs:{"href":("mailto:" + (_vm.employee.email))},domProps:{"textContent":_vm._s(_vm.employee.email)}})]):_vm._e()],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }